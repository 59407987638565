import { useNavigationStore } from "@/lib/hooks/useNavigationStore";
import { useIntersection } from "@mantine/hooks";
import { useEffect, useRef } from "react";

export function useActiveLink(link: string) {
  const containerRef = useRef<HTMLElement>(null);
  const prevLink = useRef<string | null>(null);

  const { ref, entry } = useIntersection<HTMLElement>({
    root: containerRef.current,
    threshold: 0.1,
  });

  useEffect(() => {
    if (entry?.isIntersecting) {
      prevLink.current = useNavigationStore.getState().activeLink;

      useNavigationStore.setState((state) => {
        state.activeLink = link;
      });

      return;
    }

    if (useNavigationStore.getState().activeLink === link) {
      useNavigationStore.setState((state) => {
        state.activeLink = prevLink.current ?? "";
      });
    }

    prevLink.current = null;
  }, [entry?.isIntersecting]);

  return { ref };
}

export function useActiveLinkStatic(link: string) {
  useEffect(() => {
    useNavigationStore.setState((state) => {
      state.activeLink = link;
    });
  }, []);
}
